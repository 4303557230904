/*
    Targets IE10+
    Source: https://philipnewcomer.net/2014/04/target-internet-explorer-10-11-css/
    This is used to fix this issue in IE10/11:
    https://social.msdn.microsoft.com/Forums/ie/en-US/8145c1e8-6e51-4213-ace2-2cfa43b1c018/ie-11-flexbox-with-flexwrap-wrap-doesnt-seem-to-calculate-widths-correctly-boxsizing-ignored?forum=iewebdevelopment
*/
@mixin __INTERNAL_USE_ONLY_IE_FIX__() {
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        @content;
    }
}
