$user-type: 'buyer';
@import '~dibs-sassy/exports/_spacing';
@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_breakpoints';
@import '~dibs-sassy/exports/_fonts';
@import '~dibs-sassy/exports/_zindex';
@import '~dibs-sassy/exports/_button';

.button {
    @include sassy-unstyled-button;
    box-shadow: 1px 3px 30px 0px rgba(0, 0, 0, 0.18);
    cursor: pointer;
    background-color: $sassy-color-shagreen-800;
    color: $sassy-color-white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 300ms ease;
    width: $sassy-spacing-x-large;
    height: $sassy-spacing-x-large;
    margin-left: auto;

    @include sassy-breakpoint-above(desktop) {
        @include sassy-font-body($type: heavy);
        line-height: 24px;
        border-radius: 100px;
        width: auto;
        height: 48px;
        padding: 12px $sassy-spacing-small;
        border: 2px solid $sassy-color-white;
    }

    &:hover {
        background-color: $sassy-color-shagreen-950;
    }

    @media print {
        display: none;
    }
}

.hidden {
    display: none;
}

.enter {
    animation: enterFromBottom 600ms cubic-bezier(0.44, 0, 0.12, 1.02);
}

.chatIcon {
    width: 24px;
    height: 24px;

    @include sassy-breakpoint-above(desktop) {
        width: $sassy-spacing-small;
        height: $sassy-spacing-small;
    }
}

.chatLabel {
    display: none;

    @include sassy-breakpoint-above(desktop) {
        display: block;
        user-select: none;
        margin-left: $sassy-spacing-x-small;
    }
}

.container {
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    right: 14px;
    bottom: 14px;
    left: auto;
    top: auto;
    @include sassy-z-index(middle);

    @include sassy-breakpoint-above(desktop) {
        align-items: flex-start;
        right: $sassy-spacing-small;
        bottom: $sassy-spacing-small;
    }
}
/**
* Appended to seller PDP chat container (dibs-buyer-chat)  
* to hide it when rendered together with live chat
*/
.withLiveChat {
    display: none !important; //required for specificity
}

@keyframes enterFromBottom {
    0% {
        opacity: 0;
        transform: translateY(8px);
    }
    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}
