$user-type: 'buyer';

@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_buyerLinks';
@import '~dibs-sassy/exports/_button';
@import '~dibs-sassy/exports/_internalLinks';

// user-type dependent classes are empty when compiling SCSS for excluded
// user-types and empty classes are removed when SCSS is compiled. use this
// mixin to prevent classes from being removed so that generated SCSS TS type
// defs include the classes.
@mixin keepClass() {
    /*! keep */
}

.link {
    @include sassy-unstyled-button;

    $link-color: $sassy-color-pitch-black;
    $link-hover-color: $sassy-color-pitch-black;
    $base-underline: none;

    @if $user-type == 'internal' {
        @include sassy-internal-standard-link;
    } @else {
        @if $user-type == 'dealer' {
            $link-color: $sassy-color-dealer-primary;
        } @else {
            $link-hover-color: $sassy-color-charcoal;
            $base-underline: underline;
        }
        @include __INTERNAL_USE_ONLY_BASE_LINK(
            $color: $link-color,
            $hoverColor: $link-hover-color,
            $underline: $base-underline
        );
    }
}

// link variations allowed on buyer only
// only expose class properties if buyer
// this is specifically done so the type
// def file generates these classes,
// regardless of user type
.standardLink {
    @include keepClass;
    @if $user-type == 'buyer' {
        @include sassy-buyer-standard-link;
    }
}
.invertedLink {
    @include keepClass;
    @if $user-type == 'buyer' {
        @include sassy-buyer-inverted-link;
    }
}
.contrastLinkLightInverse {
    @include keepClass;
    @if $user-type == 'buyer' {
        @include sassy-buyer-contrast-link-light-inverse;
    }
}
.contrastLinkLight {
    @include keepClass;
    @if $user-type == 'buyer' {
        @include sassy-buyer-contrast-link-light;
    }
}
.contrastLinkAlternate {
    @include keepClass;
    @if $user-type == 'buyer' {
        @include sassy-buyer-contrast-link-alternate;
    }
}
.contrastLinkNoEffect {
    @include keepClass;
    @if $user-type == 'buyer' {
        @include sassy-buyer-contrast-link-no-effect;
    }
}
.standardLinkNoEffect {
    @include keepClass;
    @if $user-type == 'buyer' {
        @include sassy-buyer-standard-link-no-effect;
    }
}
.underline {
    text-decoration: underline;
}
.dashed {
    text-decoration: underline dashed 0.5px;
}
.none {
    @include keepClass;
    @if $user-type == 'buyer' {
        text-decoration: none;
    }
}
// below .none & .underline to override text-decoration
.linkNoDecoration {
    @include keepClass;
    @if $user-type == 'buyer' {
        @include sassy-buyer-link-no-decoration;
    }
}
