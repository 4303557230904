$user-type: 'buyer';
@import '~dibs-sassy/exports/base';
@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_fonts';
@import '~dibs-sassy/exports/_spacing';

@import './global/common';
@import './global/layout';
// styles to override some default SF embedded chat styles
@import '~dibs-contact-1stdibs/exports/globalEmbeddedSFChat';

/* Offset Settings */
:root {
    --sassy-layout-offset: #{$sassy-spacing-larger};
}

@include sassy-breakpoint-below(tablet-landscape) {
    :root {
        --sassy-layout-offset: #{$sassy-spacing-large};
    }
}
@include sassy-breakpoint-below(mobile) {
    :root {
        --sassy-layout-offset: #{$sassy-spacing-small};
    }
}

/* @baseBuyerStyles overrides */
:global {
    body {
        background: $sassy-color-noir;
        color: $sassy-color-white;
        overflow-x: hidden;
        display: flex;
        flex-direction: column;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        @include sassy-font-header($size: none);
    }

    ol,
    ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }
    /* .../@baseBuyerStyles overrides */
}
