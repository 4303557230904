@import '~dibs-sassy/exports/_breakpoints';
@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_buyerLinks';

:global {
    /* clearfix class */
    .cf {
        *zoom: 1;
        &:before,
        &:after {
            content: ' ';
            display: table;
        }
        &:after {
            clear: both;
        }
    }
    .capitalize {
        text-transform: capitalize;
    }

    /*
        these classes should have !important (unless
        otherwise noted) since they are mainly overrides
    */
    .is-hidden {
        display: none !important;
    }
    .is-visible {
        visibility: visible !important;
    }
    .is-invisible {
        visibility: hidden !important;
    }

    .overflow-h {
        overflow: hidden !important;
    }

    .display-b {
        display: block !important;
    }

    .display-ib {
        display: inline-block !important;
    }

    .display-n {
        display: none !important;
    }

    .position-r {
        position: relative !important;
    }

    /*
        not sure if !important is necessary
        for the rest of these classes
    */
    .float-l {
        float: left;
    }

    .float-r {
        float: right;
    }

    .italic {
        font-style: italic;
    }

    .nobr {
        white-space: nowrap;
    }

    .text-danger {
        color: $sassy-color-alert-primary !important;
    }

    .lazy {
        /*
        // adding !important here will not
        // display any .lazy images -
        // js is needed to remove the class
        // for that to work
        */
        display: none;
    }

    .clickable {
        /*
        // used in checkout with &.expired class
        // todo: investigate implementation
        */
        cursor: pointer;
    }

    .center-text {
        text-align: center;
    }

    .mobile-overflow-y {
        overflow-y: hidden;
        height: 100%;
    }

    /* used for mobile overflow scrolling */
    .fixed-scroll {
        bottom: 0;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
    }

    .is-centered {
        bottom: 0;
        left: 0;
        margin: auto;
        position: absolute;
        right: 0;
        top: 0;
    }

    .is-zoom {
        cursor: url(https://a.1stdibscdn.com/assets/images/global/cursors/magnifier.svg),
            url(https://a.1stdibscdn.com/assets/images/global/cursors/magnifier.png),
            url(https://a.1stdibscdn.com/assets/images/global/cursors/magnifier.cur), pointer;
    }

    /* https://css-tricks.com/equidistant-objects-with-css/ */
    .equidistant {
        text-align: justify;
        &:after {
            display: inline-block;
            width: 100%;
            content: '';
        }
    }

    .flex-align-right {
        justify-content: flex-end !important;
    }
}
