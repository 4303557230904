@import '../../exports/spacing';
@import './clearfix';
@import './generateRowClasses';
@import './generateColumnClasses';

$__INTERNAL_USE_ONLY_GRID_COLUMNS__: 12;

// THESE PRODUCE MIN-WIDTH
$__INTERNAL_USE_ONLY_GRID_DEVICES__: 'xs', 'sm', 'md', 'lg', 'xl';

:global {
    .container {
        margin-right: auto;
        margin-left: auto;
        padding-left: $sassy-spacing-x-small;
        padding-right: $sassy-spacing-x-small;
        @include __INTERNAL_USE_ONLY_CLEARFIX__;

        // DEVISED FROM OLD BREAKPOINTS. I know it looks wrong, but this is what it became.
        $container-widths: (
            sm: (
                min: 320,
                max: 480,
            ),
            md: (
                min: 568,
                max: 720,
            ),
            lg: (
                min: 768,
                max: 960,
            ),
            xl: (
                min: 1025,
                max: 1140,
            ),
        ) !default;

        @each $breakpoint, $container-width in $container-widths {
            @media (min-width: map-get($container-width, min)) {
                max-width: map-get($container-width, max);
            }
        }
    }

    .containerFluid {
        margin-right: auto;
        margin-left: auto;
        padding-left: $sassy-spacing-x-small;
        padding-right: $sassy-spacing-x-small;
        @include __INTERNAL_USE_ONLY_CLEARFIX__;
    }

    @include __INTERNAL_USE_ONLY_GENERATE_GRID_ROW_CLASSES__($__INTERNAL_USE_ONLY_GRID_DEVICES__);

    @include __INTERNAL_USE_ONLY_GENERATE_GRID_COLUMN_CLASSES__(
        $__INTERNAL_USE_ONLY_GRID_COLUMNS__,
        $__INTERNAL_USE_ONLY_GRID_DEVICES__
    );
}
