$user-type: 'buyer';
@import '~dibs-sassy/exports/_zindex';

:local {
    .spinnerWrapper {
        @include sassy-z-index(overlay);
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 0;
        right: 0;
        left: 0;
        height: 100%;
    }
    .withDimBackground {
        background-color: rgba(0, 0, 0, 0.1);
    }
}
