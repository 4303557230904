@import '~dibs-sassy/exports/_colors';
$user-type: 'buyer';

@import './_fonts';
@import './_colors';
@import './_buyerLinks';
@import './_focus';
@import '../src/normalize';
@import '../src/loadedFontFaces';

:global {
    html,
    body {
        @include sassy-font-body(small);
        height: 100%;
        width: 100%;
        // https://1stdibs.atlassian.net/browse/CAM-918
        // https://1stdibs.atlassian.net/browse/TRADE-5404
        font-weight: 300;
    }

    body {
        line-height: 1.5;
    }

    a {
        $link-color: $sassy-color-pitch-black;
        $link-hover-color: $sassy-color-pitch-black;
        $link-decoration: none;

        @if $user-type == 'internal' {
            $link-color: $sassy-color-internal-primary;
        } @else if $user-type == 'dealer' {
            $link-color: $sassy-color-dealer-primary;
        }

        @if $user-type == 'buyer' {
            $link-decoration: underline;
            $link-hover-color: $sassy-color-charcoal;
        }

        @include __INTERNAL_USE_ONLY_BASE_LINK(
            $color: $link-color,
            $hoverColor: $link-hover-color,
            $underline: $link-decoration
        );
    }

    *,
    *:before,
    *:after {
        box-sizing: border-box;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-weight: normal;
    }

    p,
    pre {
        margin: 0;
    }

    // fixes issue in IE11 where lazy-loaded images stretch beyond boundary
    svg:not(:root) {
        overflow: hidden;
    }

    // fixes issue in IE11 where clearable X shows up
    input::-ms-clear {
        display: none;
    }

    // chrome and safari add a "clear" pseudo-element to input[type="search"], which
    // conflicts with 1stdibs styles, and is inaccessible to boot. 1stdibs input
    // designs already have an explicit clear button so the browser-based clear
    // button should be suppressed on all 1stdibs pages.
    // https://developer.mozilla.org/en-US/docs/Web/CSS/::-webkit-search-cancel-button
    input[type='search']::-webkit-search-cancel-button {
        display: none;
    }

    b,
    strong {
        @include sassy-font-body($type: heavy, $size: none);
    }

    // FI-2124 changed with dibs-css component layer class. still used in few places remove this class with FI-2140
    .dibsIcon {
        max-width: 100%;
        max-height: 100%;
        width: auto;
        height: auto;
        fill: currentColor;
    }

    :focus:not(.focus-visible) {
        // do not combine focus-visible selectors otherwise they will not apply in
        // browsers that do not support :focus-visible. to prevent cssnano from combining rules,
        // add an extra property to differentiate the rules.
        outline: auto;
        // remove :focus ring when button is not .focus-visible (polyfill)
        // see https://github.com/WICG/focus-visible
        outline: none;
    }
    :focus:not(:focus-visible) {
        // remove :focus ring when button is not :focus-visible (very lightly supported)
        outline: none;
    }

    @include sassy-focus-visible-selector() {
        @include sassy-focus-ring();
    }

    @if $user-type == 'buyer' {
        // bc buyer header/footer is dark, it requires a lighter outline color
        header,
        footer {
            @include sassy-focus-visible-selector() {
                @include sassy-focus-ring($outlineColor: $sassy-color-storm);
            }
        }
    }
}
