@import '../exports/_colors';

@mixin sassy-focus-ring($outlineColor: $sassy-color-satan, $outlineWidth: 2px) {
    outline: $outlineColor solid $outlineWidth;
}

@mixin sassy-focus-visible-selector($parentSelector: false) {
    @if $parentSelector {
        &:global(.focus-visible) {
            // browsers that do not support :focus-visible need extra rules to prevent
            // cssnano from combining selectors containing the same rules
            outline: none;
            @content;
        }
        &:focus-visible {
            @content;
        }
    } @else {
        :global(.focus-visible) {
            // browsers that do not support :focus-visible need extra rules to prevent
            // cssnano from combining selectors containing the same rules
            outline: none;
            @content;
        }
        :focus-visible {
            @content;
        }
    }
}
