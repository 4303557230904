// load ProximaNova from adobe typekit
// https://1stdibs.atlassian.net/browse/FI-2293
@import url('https://use.typekit.net/mkk3fxh.css');

@font-face {
    font-family: 'Cardinal Classic Short';
    src: url('https://a.1stdibscdn.com/dist/fonts/CardinalClassic/cardinalclshortw-regular.woff')
        format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Cardinal Classic Short';
    src: url('https://a.1stdibscdn.com/dist/fonts/CardinalClassic/cardinalclshortw-italic.woff')
        format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}
