$user-type: 'buyer';
$animation-timing-function: cubic-bezier(0.4, 0, 0.6, 1);
$animation-props: 2s linear infinite both;

@keyframes animateChatFirstDot {
    0% {
        transform: translate(1.5px, 1.5px);
    }
    33.333% {
        transform: translate(1.5px, 1.5px);
        animation-timing-function: $animation-timing-function;
    }
    40% {
        transform: translate(1.5px, -1.5px);
        animation-timing-function: $animation-timing-function;
    }
    46.667% {
        transform: translate(1.5px, 1.5px);
    }
    100% {
        transform: translate(1.5px, 1.5px);
    }
}

@keyframes animateChatSecondDot {
    0% {
        transform: translate(7.5px, 1.5px);
    }
    37.778% {
        transform: translate(7.5px, 1.5px);
        animation-timing-function: $animation-timing-function;
    }
    44.444% {
        transform: translate(7.5px, -1.5px);
        animation-timing-function: $animation-timing-function;
    }
    51.111% {
        transform: translate(7.5px, 1.5px);
    }
    100% {
        transform: translate(7.5px, 1.5px);
    }
}

@keyframes animateChatThirdDot {
    0% {
        transform: translate(13.5px, 1.5px);
    }
    42.222% {
        transform: translate(13.5px, 1.5px);
        animation-timing-function: $animation-timing-function;
    }
    46.667% {
        transform: translate(13.5px, -1.5px);
        animation-timing-function: $animation-timing-function;
    }
    55.556% {
        transform: translate(13.5px, 1.5px);
    }
    100% {
        transform: translate(13.5px, 1.5px);
    }
}

.icon {
    &.showAnimation {
        .firstDot {
            animation: animateChatFirstDot $animation-props;
        }
        .secondDot {
            animation: animateChatSecondDot $animation-props;
        }
        .thirdDot {
            animation: animateChatThirdDot $animation-props;
        }
    }
}
