$user-type: 'buyer';
@import '~dibs-sassy/exports/_fonts';
@import '~dibs-sassy/exports/_spacing';

:local {
    .title {
        @include sassy-font-header($size: none);
        margin: 0;

        &.small {
            @include sassy-font-header($type: none, $size: small);
        }

        &.medium {
            @include sassy-font-header($type: none, $size: medium);
        }

        &.large {
            @include sassy-font-header($type: none, $size: large);
        }

        &.xLarge {
            @include sassy-font-header($type: none, $size: x-large);
        }

        &.xxLarge {
            @include sassy-font-header($type: none, $size: xx-large);
        }
    }

    .subtitle {
        @include sassy-font-body($size: none);

        &.small {
            @include sassy-font-body($type: none, $size: x-small);
            margin-top: 0;
        }

        &.medium {
            @include sassy-font-body($type: none, $size: small);
            margin-top: 0;
        }

        &.large {
            @include sassy-font-body($type: none, $size: medium);
            margin-top: $sassy-spacing-x-small;
        }

        &.xLarge,
        &.xxLarge {
            @include sassy-font-body($type: none, $size: large);
            margin-top: $sassy-spacing-x-small;
        }
    }
}
