$user-type: 'buyer';

@import '~dibs-sassy/exports/_colors';

@mixin colorSolid($color) {
    fill: $color;
    stop-color: $color;
}

:local {
    $colorLight: $sassy-color-white;
    $colorShadow: $sassy-color-dolphin;
    $colorDark: $sassy-color-buyer-primary;

    @if $user-type == 'internal' {
        $colorDark: $sassy-color-internal-primary;
    } @else if $user-type == 'dealer' {
        $colorDark: $sassy-color-dealer-primary;
    }
    .spinner {
        animation: spin 2s infinite linear;
        width: 100%;
        height: 100%;
    }

    .shadow {
        fill: none;
        stroke-width: 37px;
    }

    @keyframes :local(spin) {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }

    .small {
        width: 40px;
        height: 40px;
    }

    .tiny {
        width: 16px;
        height: 16px;
    }

    .colorLightSolid {
        @include colorSolid($colorLight);
    }

    .colorLightTransparent {
        @include colorSolid($colorLight);
        stop-opacity: 0;
    }

    .colorDarkShadow {
        stroke: $colorShadow;
    }

    .colorLightShadow {
        stroke: transparent;
    }

    .colorBlackSolid {
        @include colorSolid($sassy-color-noir);
    }

    .colorDarkSolid {
        @include colorSolid($colorDark);
    }

    .colorDarkTransparent {
        @include colorSolid($colorDark);
        stop-opacity: 0;
    }
}
