@import '../../exports/spacing';
@import '../breakpoints';
@import './strCamelCase';
@import './media-query-IE-fix';

@mixin __INTERNAL_USE_ONLY_GENERATE_ROW_ALIGN_CLASSES__($device) {
    $align-top: __INTERNAL_USE_ONLY_STR_CAMEL_CASE__(row-#{$device}-top);
    $align-center: __INTERNAL_USE_ONLY_STR_CAMEL_CASE__(row-#{$device}-center);
    $align-bottom: __INTERNAL_USE_ONLY_STR_CAMEL_CASE__(row-#{$device}-bottom);
    $stretch: __INTERNAL_USE_ONLY_STR_CAMEL_CASE__(row-#{$device}-stretch);
    .#{$align-top} {
        align-items: flex-start;
    }
    .#{$align-center} {
        align-items: center;
    }
    .#{$align-bottom},
    .#{$stretch} {
        align-items: flex-end;
    }
}

@mixin __INTERNAL_USE_ONLY_GENERATE_GRID_ROW_CLASSES__($devices) {
    .rowFlexTransition,
    .rowFlex {
        display: flex;
        flex-wrap: wrap;
        margin-left: -$sassy-spacing-x-small;
        margin-right: -$sassy-spacing-x-small;

        @include __INTERNAL_USE_ONLY_IE_FIX__() {
            margin-left: 0;
            margin-right: 0;
            justify-content: space-between;
        }
    }
    @each $size in $devices {
        $device: mobile;
        @if $size == 'sm' {
            $device: mobile;
        } @else if $size == 'md' {
            $device: tablet-portrait;
        } @else if $size == 'lg' {
            $device: tablet-landscape;
        } @else if $size == 'xl' {
            $device: desktop;
        }
        @if $device == mobile {
            @include __INTERNAL_USE_ONLY_GENERATE_ROW_ALIGN_CLASSES__($size);
        } @else {
            @include sassy-breakpoint-above($device) {
                @include __INTERNAL_USE_ONLY_GENERATE_ROW_ALIGN_CLASSES__($size);
            }
        }
    }
}
