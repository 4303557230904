@import '~dibs-sassy/exports/_breakpoints';
@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_fonts';
@import '~dibs-sassy/exports/_zindex';
@import '~dibs-sassy/exports/_spacing';

// used for SF docked chat styles
:global {
    .embeddedServiceSidebar.layout-docked .dockableContainer.showDockableContainer {
        right: 14px;
        color: $sassy-color-noir;

        h2 {
            @include sassy-font-sub-header;
        }

        h3 {
            @include sassy-font-body($size: large);
        }

        @include sassy-breakpoint-below(tablet-portrait) {
            right: 0;
            height: 100%;
            max-width: 100%;
            margin: 0;
            border-radius: 0;
        }

        @include sassy-breakpoint-above(desktop) {
            right: $sassy-spacing-small;
        }

        button[embeddedService-chatHeader_chatHeader] {
            @include sassy-breakpoint-below(tablet-portrait) {
                width: unset;

                &:hover::before {
                    right: unset;
                    margin-left: -1px;
                }

                svg {
                    height: 100%;
                }
            }
        }

        @include sassy-breakpoint-below(tablet-portrait) {
            button {
                /*
                 * Fixes bug for SF chat window where minimize and close buttons
                 * are not visible on mobile devices
                 * https://trailhead.salesforce.com/trailblazer-community/feed/0D54S00000Dcp7NSAR
                 *
                 * Note: emulating mobile device shows buttons without the fix
                 */
                &.minimizeButton,
                &.closeButton {
                    font-size: 9px !important;
                }
            }
        }

        .embeddedServiceLiveAgentStateChatInputFooter.chasitorInputWrapper {
            @include sassy-breakpoint-below(tablet-portrait) {
                border-radius: 0;
            }
        }

        .embeddedServiceSidebarButton .label {
            @include sassy-font-body;
        }
    }

    .embeddedServiceSidebar.sidebarMaximized.modalContainer {
        z-index: 10031;
    }

    .embeddedServiceSidebar.sidebarMinimized.modalContainer {
        /**
         * Ensures minimised SF chat button is rendered under bottom
         * banners, so it would not cover banner close button
         */
        @include sassy-z-index(middle);
    }
}
