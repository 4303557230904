$user-type: 'buyer';

@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_spacing';

:local {
    .bottomBorder {
        width: 60px;
        height: 2px;
        margin: 0 auto;

        &.small,
        &.medium {
            margin-top: $sassy-spacing-x-small;
        }

        &.large,
        &.xLarge,
        &.xxLarge {
            margin-top: $sassy-spacing-small;
        }

        @if $user-type == 'internal' {
            background-color: $sassy-color-noir;
        } @else if $user-type == 'dealer' {
            background-color: $sassy-color-dealer-primary;
        } @else {
            background-color: $sassy-color-buyer-primary;
        }

        &.isTrade {
            background-color: $sassy-color-trade-primary;
        }
    }
}
