@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_fonts';

@import '../shared/_layout';
@import '../shared/_headerVariables';
@import '../shared/_variables';

:global {
    .primary-viewport-width {
        // use this class if your element needs to
        // break outside of the #mainContent width
        // ex:
        // <div id="mainContent">
        //   <div class="primary-viewport-width">
        //     <div class="primary-page-width">...content...</div>
        //   </div>
        // </div>
        @include primary-viewport-width;
    }
    .primary-page-width {
        @include primary-page-width;
    }

    .primary-legacy-resp-page-width {
        min-width: $dbl-legacy-responsive-min-width;
    }

    body.primary-resp-page-width {
        @media (max-width: $dbl-responsive-min-width - 1) {
            overflow-x: scroll;
        }
    }

    .primary-resp-page-width {
        min-width: $dbl-responsive-min-width;
    }

    .page {
        background: $sassy-color-white;
        color: $sassy-color-pitch-black;
        @include sassy-font-body($size: x-small, $type: none);
        position: relative;
        padding: 0;
        width: 100%;
        flex-grow: 1;
        flex-shrink: 0;
    }

    .main-content-resp,
    .main-content-mobile {
        @include primary-page-width;
        clear: both;
    }

    #mainContent {
        &:focus {
            // prevent outline when focused via skipToContent button
            outline: none;
        }
    }
}
